import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import { COMPONENT_INIT, REDUCED_MOTION_CHANGE } from '../lib/events';

export default el => {

    let observer;
    let isPaused = true;
    let motionReduced = false;
    let isVisible = false;
    let tl;

    const scrollers = Array.from(el.querySelectorAll('[data-scroller]'));
    const mobileScroller = scrollers[0];
    const desktopScrollers = [scrollers[1], scrollers[2]];

    const getIsSmall = () => mobileScroller.offsetParent !== null;

    let isSmall = getIsSmall();
    let { scrollTop: prevScrollTop } = Viewport;
    let scrollDirection = 'down';
    let scrollTimeout = null;
    let preventScrollHandler = false;
    let preventScrollHandlerTimeout = null;

    const preloadImages = () => {
        if (!window.lazySizes) {
            return;
        }
        el.querySelectorAll('img:not(.lazyloaded):not(.lazyloading)').forEach(img => {
            if (img.offsetParent === null) {
                return;
            }
            window.lazySizes.loader.unveil(img);
        });
    };

    const clearPreventScrollHandlerTimeout = () => {
        if (!preventScrollHandlerTimeout) {
            return;
        }
        clearTimeout(preventScrollHandlerTimeout);
        preventScrollHandlerTimeout = null;
    };

    const clearScrollTimeout = () => {
        if (!scrollTimeout) {
            return;
        }
        clearTimeout(scrollTimeout);
        scrollTimeout = null;
    };

    const createTimeline = () => {
        if (tl || motionReduced) {
            return;
        }
        tl = gsap.timeline({
            onStart() {
                preloadImages();
            },
            onComplete() {
                this.play(0);
            },
            onReverseComplete() {
                this.reverse(0);
            }
        });
        let duration;
        if (isSmall) {
            duration = Array.from(mobileScroller.children).length * 1.5;
            if (!duration) {
                return;
            }
            tl
                .set(desktopScrollers, { clearProps: 'transform' }, 0)
                .fromTo(mobileScroller, { xPercent: 0 }, { xPercent: -50, duration, ease: 'none' }, 0);
        } else {
            duration = Array.from(desktopScrollers[0].children).length * 10;
            if (!duration) {
                return;
            }
            tl
                .set(mobileScroller, { clearProps: 'transform' }, 0)
                .fromTo(desktopScrollers[0], { yPercent: 0 }, { yPercent: -50, duration, ease: 'none' }, 0)
                .fromTo(desktopScrollers[1], { yPercent: 0 }, { yPercent: 50, duration, ease: 'none' }, 0);
        }
        if (isPaused) {
            tl.pause();
        }
    };

    const pause = () => {
        isPaused = true;
        if (tl) {
            gsap.killTweensOf(tl);
            tl.timeScale(1);
            tl.pause();
        }
    };

    const play = () => {
        isPaused = false;
        if (tl && !motionReduced && isVisible) {
            tl.resume();
        }
    };

    const onScroll = () => {
        clearScrollTimeout();
        const { scrollTop } = Viewport;
        scrollDirection = scrollTop < prevScrollTop ? 'up' : 'down';
        const scrollDelta = Math.max(-100, Math.min(scrollTop - prevScrollTop, 100));
        prevScrollTop = scrollTop;
        if (isSmall || !tl || isPaused || preventScrollHandler) {
            return;
        }
        let timeScale = tl.timeScale();
        timeScale += scrollDelta;
        timeScale = Math.max(-100, Math.min(timeScale, 100));
        gsap.to(tl, { timeScale, duration: 0.1, ease: 'none' });
        scrollTimeout = setTimeout(() => {
            gsap.to(tl, { timeScale: scrollDirection === 'up' ? -1 : 1, duration: 0.3, ease: 'Cubic.easeOut' });
            clearScrollTimeout();
        }, 10);
    };

    const onResize = () => {
        const wasSmall = isSmall;
        isSmall = getIsSmall();
        preventScrollHandler = true;
        clearPreventScrollHandlerTimeout();
        preventScrollHandlerTimeout = setTimeout(() => {
            preventScrollHandler = false;
        }, 100);
        if (isSmall === wasSmall || !tl) {
            return;
        }
        clearScrollTimeout();
        gsap.killTweensOf(tl);
        tl.kill();
        tl = null;
        gsap.killTweensOf(scrollers);
        gsap.set(scrollers, { clearProps: 'transform' });
        if (!isPaused) {
            createTimeline();
        }
    };

    const onReducedMotionChange = (key, reduceMotion) => {
        motionReduced = reduceMotion;
        if (motionReduced) {
            pause();
        } else {
            play();
        }
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            isVisible = isIntersecting;
            if (!isVisible) {
                pause();
            } else {
                createTimeline();
                play();
            }
        });
        observer.observe(el);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Dispatch.on(REDUCED_MOTION_CHANGE, onReducedMotionChange, true);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.on('resize', onResize);
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        clearScrollTimeout();
        clearPreventScrollHandlerTimeout();
        Dispatch.off(REDUCED_MOTION_CHANGE, onReducedMotionChange);
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
