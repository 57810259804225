import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import superagent from '../core/request';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default form => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const content = $form.find('[data-content]').get(0);
    const receipt = $form.find('[data-receipt]').get(0);
    const hideReceiptBtn = $form.find('button[data-hide-receipt-btn]').get(0);
    const submitBtn = $form.find('button[type="submit"]').get(0);

    const announcer = $form.find('[data-announcer]').get(0);
    const statuses = JSON.parse(announcer.dataset.statuses);

    let isSubmitting = false;
    let receiptVisible = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    // Clear input fields
    const clearInputs = () => {
        $form.find('input:not([type="hidden"]),textarea,select').get().forEach(input => {
            if (input.nodeName === 'SELECT') {
                input.selectedIndex = 0;
            } else if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                input.checked = false;
            } else {
                input.value = null;
            }
            try {
                input.dispatchEvent(new Event('change'));
            } catch (error) {
                console.error(error);
            }
        });
    };

    const hideReceipt = () => {
        if (!receiptVisible) {
            return;
        }
        enableSubmit();
        gsap.timeline()
            .to(receipt, { opacity: 0, duration: 0.3 })
            .add(() => {
                receipt.hidden = true;
                content.removeAttribute('tabindex');
                content.classList.remove('pointer-events-none');
                receiptVisible = false;
                try {
                    const firstInput = $form.find('[data-input]').find('input:not([type="hidden"]),textarea,select').get(0);
                    if (firstInput) {
                        try {
                            firstInput.focus();
                        } catch (error) {
                            console.error(error);
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            })
            .to(content, { opacity: 1, duration: 0.3 }, 'in');
    };

    const showReceipt = () => {
        if (receiptVisible) {
            return;
        }
        receiptVisible = true;
        gsap.timeline()
            .to(content, { opacity: 0, duration: 0.3 })
            .add(() => {
                receipt.hidden = false;
                content.setAttribute('tabindex', '-1');
                content.classList.add('pointer-events-none');
                clearInputs();
                try {
                    hideReceiptBtn.focus();
                } catch (error) {
                    console.error(error);
                }
            })
            .fromTo(receipt, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 'in');
    };

    const showGenericError = () => {
        errorMessage.hidden = false;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-errors]').each(node => {
            node.textContent = '';
            node.hidden = true;
        });
        $form.find('[data-input]').removeClass('has-errors');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('has-errors');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                $(errorsDiv).html([].concat(errorsByName[name]).join('<br/>'));
            }
        });

        const firstErrorInput = $form.find('[data-input].has-errors').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
            } catch (error) {
                // Don't care.
            }
        }

        enableSubmit();

    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        isSubmitting = true;
        disableSubmit();

        submitBtn.classList.add('loading');

        announcer.textContent = statuses.submitting;

        const data = serialize($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        superagent
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                clearErrors();
                if (status !== 200 || !body.success) {
                    throw new Error();
                }
                announcer.textContent = statuses.submitted;
                showReceipt();
            })
            .catch(error => {
                const { response = {} } = error || {};
                const { errors } = response.body || {};
                if (errors) {
                    announcer.textContent = statuses.errors;
                } else {
                    announcer.textContent = statuses.failure;
                }
                showErrors(errors);
                enableSubmit();
            })
            .then(() => {
                isSubmitting = false;
                submitBtn.classList.remove('loading');
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').each(link => {
            $(link).attr({
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        });
        hideReceiptBtn.addEventListener('click', hideReceipt);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
        hideReceiptBtn.removeEventListener('click', hideReceipt);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    return {
        init,
        destroy
    };
};
