import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import {
    COMPONENT_INIT,
    MENU_CLOSED,
    MENU_CLOSING,
    MENU_OPENED,
    MENU_OPENING,
    UNLOAD
} from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const burger = el.querySelector('button[aria-expanded]');
    const menu = burger.nextElementSibling;
    const pageHeader = document.getElementById('top');

    let isOpen = false;
    let burgerTl;
    let menuTl;

    gsap.set(menu, { opacity: 0 });

    const createBurgerTl = () => {
        if (burgerTl) {
            return;
        }
        const spans = Array.from(burger.querySelector('[data-burger]').children);
        burgerTl = gsap.timeline({ paused: true })
            .to(spans[0], {
                y: 10,
                duration: 0.5,
                ease: 'Quad.easeIn'
            }, 'collapse')
            .to(spans[2], {
                y: -10,
                duration: 0.5,
                ease: 'Quad.easeIn'
            }, 'collapse')
            .set(spans[1], { opacity: 0 })
            .to(spans[0], {
                rotate: 45,
                duration: 0.5,
                ease: 'Quad.easeOut'
            }, 'rotate')
            .to(spans[2], {
                rotate: -45,
                duration: 0.5,
                ease: 'Quad.easeOut'
            }, 'rotate');
    };

    const onItemMouseOver = e => {
        if (document.documentElement.classList.contains('using-touch')) {
            return;
        }
        const items = $(menu)
            .find('[data-item]')
            .get();
        gsap.killTweensOf(items);
        gsap.to(items, {
            opacity: 0.4,
            duration: 0.3
        });
        gsap.to(e.triggerTarget, {
            opacity: 1,
            duration: 0.3
        });
    };

    const onItemMouseLeave = e => {
        const items = $(menu)
            .find('[data-item]')
            .get();
        gsap.to(items, {
            opacity: 1,
            delay: 0.15,
            duration: 0.3
        });
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        createBurgerTl();
        isOpen = true;
        burger.setAttribute('aria-expanded', 'true');
        document.body.classList.remove('menu-closing');
        document.body.classList.add('menu-opening');
        menu.hidden = false;
        Dispatch.emit(MENU_OPENING);
        gsap.to(burgerTl, {
            progress: 1,
            duration: 0.25,
            ease: 'none'
        });
        if (menuTl) {
            menuTl.kill();
        }
        const burgerLabel = burger.querySelector('[data-label]');
        const burgerCloseLabel = burgerLabel.nextElementSibling;
        const menuItems = menu.querySelectorAll('[data-items]');
        const menuOverlay = menu.querySelector('[data-overlay]');
        gsap.killTweensOf([menu, menuItems, menuOverlay]);
        menuTl = gsap.timeline({
            onComplete() {
                document.body.classList.remove('menu-opening');
                document.body.classList.add('menu-open');
                gsap.set([menu, menuItems], { clearProps: 'all' });
                Dispatch.emit(MENU_OPENED);
            }
        })
            .to(burgerLabel, {
                opacity: 0,
                duration: 0.3
            }, 0.15)
            .fromTo(burgerCloseLabel, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0.15)
            .to(menu, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(menuItems, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3,
                ease: 'Cubic.easeIn'
            }, 0)
            .fromTo(menuItems, { y: 20 }, {
                y: 0,
                duration: 0.75,
                stagger: 0.15,
                ease: 'Quint.easeOut'
            }, 0);
        Viewport.lockTabbing(pageHeader, burger);
        $(menu)
            .find('[data-item]')
            .on('mouseover', onItemMouseOver);
        $(menu)
            .find('[data-item]')
            .on('mouseleave', onItemMouseLeave);
    };

    const afterClose = () => {
        document.body.classList.remove('menu-closing');
        Dispatch.emit(MENU_CLOSED);
        menu.hidden = true;
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        burger.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('menu-open');
        document.body.classList.remove('menu-opening');
        document.body.classList.add('menu-closing');
        Viewport.releaseTabbing(burger);
        Dispatch.emit(MENU_CLOSING);
        if (menuTl) {
            menuTl.kill();
            menuTl = null;
        }
        const burgerLabel = burger.querySelector('[data-label]');
        const burgerCloseLabel = burgerLabel.nextElementSibling;
        const menuItems = menu.querySelectorAll('[data-items]');
        const menuOverlay = menu.querySelector('[data-overlay]');
        gsap.killTweensOf([menu, menuItems, menuOverlay]);
        if (tween) {
            gsap.to(burgerTl, {
                progress: 0,
                duration: 0.25,
                ease: 'none'
            });
            menuTl = gsap.timeline()
                .to(burgerLabel, {
                    opacity: 1,
                    duration: 0.29
                }, 0)
                .to(burgerCloseLabel, {
                    opacity: 0,
                    duration: 0.29
                }, 0)
                .to(menu, {
                    opacity: 0,
                    duration: 0.29,
                    onComplete: afterClose
                }, 0);
        } else {
            gsap.set(burgerTl, { progress: 0 });
            gsap.set(burgerLabel, { opacity: 1 });
            gsap.set(burgerCloseLabel, { opacity: 0 });
            afterClose();
        }
        $(menu)
            .find('[data-item]')
            .off('mouseover mouseleave');
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen) {
            return;
        }
        const key = e.key || e.keyCode || e.which || null;
        if (key === 'Escape' || key === 27) {
            close();
        }
    };

    const onPageUnload = () => {
        if (!isOpen) {
            return;
        }
        close();
    };

    const init = () => {
        burger.addEventListener('click', toggle);
        document.body.addEventListener('keyup', onBodyKeyUp);
        Dispatch.on(UNLOAD, onPageUnload);
    };

    const destroy = () => {
        burger.removeEventListener('click', toggle);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        Dispatch.off(UNLOAD, onPageUnload);
        if (isOpen) {
            close(false);
        }
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
