import gsap from 'gsap';
import $ from '../core/Dom';

const tweens = new WeakMap();

export default {
    init() {
        // Init links
        const $body = $(document.body);
        $body.on('mouseover', 'a,button:not([aria-disabled="true"]):not(.disabled)', e => {
            if (document.documentElement.classList.contains('using-touch')) {
                return;
            }
            const { triggerTarget: target } = e;
            if (target.classList.contains('js-hover')) {
                return;
            }
            // Animated links
            let links = [];
            if (target.classList.contains('animated-link')) {
                links = [target];
            } else if (target.classList.contains('group')) {
                links = Array.from(target.querySelectorAll('.animated-link'));
            }
            if (links.length) {
                target.classList.add('js-hover');
                let tween = tweens.get(target);
                if (!tween) {
                    tween = gsap.timeline({ paused: true })
                        .set(links, {
                            backgroundPosition: '0% 100%'
                        })
                        .to(links, {
                            backgroundSize: '100% 1px',
                            duration: 0.3,
                            ease: 'Power2.easeOut'
                        })
                        .set(links, {
                            backgroundPosition: '100% 100%'
                        })
                        .to(links, {
                            backgroundSize: '0% 1px',
                            duration: 0.35,
                            ease: 'Power2.easeOut'
                        }, 'out');
                    tweens.set(target, tween);
                }
                tween.pause(0);
                gsap.to(tween, { time: tween.labels.out, duration: 0.3, ease: 'none' });
                $(target)
                    .on('mouseleave', () => {
                        $(target)
                            .off('mouseleave');
                        target.classList.remove('js-hover');
                        tween.play();
                    });
                return;
            }
            // Animated arrows
            const animatedArrow = target.querySelector('[data-anim-arrow]');
            if (animatedArrow) {
                target.classList.add('js-hover');
                let tween = tweens.get(target);
                if (!tween) {
                    const svg = animatedArrow.querySelector('svg');
                    const svgCopy = svg.cloneNode(true);
                    svgCopy.style.position = 'absolute';
                    svgCopy.style.left = 0;
                    svgCopy.style.top = 0;
                    animatedArrow.appendChild(svgCopy);
                    const group = animatedArrow.closest('.group') || target;
                    const { width } = group.getBoundingClientRect();
                    tween = gsap.timeline({ paused: true })
                        .fromTo(svgCopy, { x: -width }, { x: 0, duration: 0.3, ease: 'Power1.easeOut' }, 0)
                        .fromTo(svgCopy, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeInOut' }, 0)
                        .fromTo(svg, { x: 0 }, { x: width, duration: 0.3, ease: 'Power1.easeIn' }, 0)
                        .fromTo(svg, { opacity: 1 }, { opacity: 0, duration: 0.3, ease: 'Cubic.easeInOut' }, 0);
                    tween.timeScale(1.2);
                    tweens.set(target, tween);
                }
                tween.play();
                $(target)
                    .on('mouseleave', () => {
                        $(target).off('mouseleave');
                        target.classList.remove('js-hover');
                        tween.reverse();
                    });
            }
        });
    }
};
