import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const toggle = el.querySelector('button');
    const menu = toggle.nextElementSibling;

    const { position } = el.dataset;

    let isExpanded = false;
    let tl;
    let toggleObserver;

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        toggle.setAttribute('aria-expanded', 'true');
        if (position === 'top') {
            menu.classList.add('bottom-100');
        } else {
            menu.classList.add('top-100');
        }
        menu.hidden = false;
        menu.querySelector('a').focus();
        gsap.killTweensOf(menu);
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                tl.kill();
                tl = null;
            }
        })
            .fromTo(menu, { y: position === 'top' ? 16 : -16 }, { y: 0, duration: 0.35, ease: 'Back.easeOut' }, 0)
            .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0);
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        toggle.setAttribute('aria-expanded', 'false');
        if ((!document.activeElement || menu.contains(document.activeElement)) && Viewport.visible(toggle)) {
            toggle.focus();
        }
        gsap.killTweensOf(menu);
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (tween) {
            tl = gsap.timeline({
                onComplete() {
                    menu.hidden = true;
                    tl.kill();
                    tl = null;
                }
            })
                .to(menu, { y: position === 'top' ? 10 : -10, duration: 0.3, ease: 'Quad.easeIn' }, 0)
                .to(menu, { opacity: 0, duration: 0.3 }, 0);
        } else {
            menu.hidden = true;
        }
    };

    const onToggleClick = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onBodyClickFocus = e => {
        if (!isExpanded) {
            return;
        }
        const { target } = e;
        if (target === toggle || toggle.contains(target) || target === menu || menu.contains(target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded || e.key !== 'Escape') {
            return;
        }
        collapse();
    };

    const init = () => {
        toggle.addEventListener('click', onToggleClick);
        document.body.addEventListener('click', onBodyClickFocus);
        document.body.addEventListener('focusin', onBodyClickFocus);
        document.body.addEventListener('keyup', onBodyKeyUp);
        toggleObserver = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                collapse(Viewport.visible(menu));
            }
        });
        toggleObserver.observe(toggle);
    };

    const destroy = () => {
        collapse(false);
        toggle.removeEventListener('click', onToggleClick);
        document.body.removeEventListener('click', onBodyClickFocus);
        document.body.removeEventListener('focusin', onBodyClickFocus);
        document.body.addEventListener('keyup', onBodyKeyUp);
        toggleObserver.disconnect();
        toggleObserver = null;
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
