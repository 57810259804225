import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import {
    //ACCORDION_COLLAPSE,
    //ACCORDION_EXPAND,
    COMPONENT_INIT,
    MENU_CLOSED
} from '../lib/events';

const INSTANCES = new WeakMap();

export default (el, props = {}) => {

    const button = el.querySelector('button[aria-controls]');
    const region = el.querySelector(`#${button.getAttribute('aria-controls')}`);

    if (!button || !region) {
        console.warn('Accordion missing button or region');
        return null;
    }

    const image = region.querySelector('[data-image]');
    const text = region.querySelector('[data-text]');
    const { closeOthers = false } = props || {};

    let isExpanded = false;
    let tl;

    const expand = () => {
        if (isExpanded) {
            return;
        }
        //Dispatch.emit(ACCORDION_EXPAND, el);
        isExpanded = true;
        region.hidden = false;
        button.style.transitionDelay = '';
        button.setAttribute('aria-expanded', 'true');
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                tl = null;
            }
        })
            .to(region, {
                height: 'auto',
                duration: 0.5,
                ease: 'Power3.easeInOut'
            }, 0);
        if (text) {
            tl.fromTo(text, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3,
                ease: 'Cubic.easeIn'
            }, 0);
            tl.fromTo(text, { y: 40 }, {
                y: 0,
                duration: 0.55,
                ease: 'Power3.easeOut'
            }, 0);
        }
        if (image) {
            tl.fromTo(image, { opacity: 0 }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeIn'
            }, 0);
            tl.fromTo(image, { y: 20 }, {
                y: 0,
                duration: 0.5,
                ease: 'Power3.easeOut'
            }, 0);
        }
        tl.to(button.querySelector('path'), {
            scaleY: 0,
            duration: 0.3,
            transformOrigin: 'center center',
            ease: 'Power2.easeInOut'
        }, 0);
        if (closeOthers) {
            const siblings = el.parentNode.querySelectorAll('[data-component="Accordion"]');
            siblings.forEach(sibling => {
                if (sibling === el) {
                    return;
                }
                const accordion = INSTANCES.get(sibling);
                if (!accordion) {
                    return;
                }
                accordion.collapse();
            });
        }
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        //Dispatch.emit(ACCORDION_COLLAPSE, el);
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        button.style.transitionDelay = '300ms';
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                region.hidden = true;
                button.style.transitionDelay = '';
                tl = null;
            }
        })
            .to(region, {
                height: 0,
                duration: 0.5,
                ease: 'Power3.easeInOut'
            }, 0);
        if (text) {
            tl.to(text, {
                opacity: 0,
                duration: 0.3
            }, 0);
        }
        if (image) {
            tl.to(image, {
                opacity: 0,
                duration: 0.3
            }, 0);
            tl.to(image, {
                y: 5,
                duration: 0.3,
                ease: 'Power3.easeIn'
            }, 0);
        }
        tl.to(button.querySelector('path'), {
            scaleY: 1,
            duration: 0.3,
            transformOrigin: 'center center',
            ease: 'Power2.easeInOut'
        }, 0);
        if (!tween) {
            tl.progress(1);
        }
    };

    const onClick = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onMenuClosed = () => {
        if (!isExpanded || !el.closest('#top')) {
            return;
        }
        collapse(false);
    };

    const init = () => {
        Dispatch.on(MENU_CLOSED, onMenuClosed);
        button.addEventListener('click', onClick);
    };

    const destroy = () => {
        Dispatch.off(MENU_CLOSED, onMenuClosed);
        button.removeEventListener('click', onClick);
        INSTANCES.delete(el);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    const accordion = {
        init,
        destroy,
        collapse
    };

    INSTANCES.set(el, accordion);

    return accordion;

};
