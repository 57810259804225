import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import AnimatedLinks from './lib/AnimatedLinks';
import Revealer from './lib/Revealer';
import Transition from './lib/Transition';

import { isLivePreview } from './lib/helpers';
import Dispatch from './core/Dispatch';
import { REDUCED_MOTION_CHANGE } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
    AnimatedLinks.init();

    const reduceMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)');

    const onReduceMotionChange = () => {
        if (!reduceMotionQuery.matches) {
            if (!isLivePreview()) {
                Revealer.init();
                Transition.init();
            }
        } else {
            Revealer.destroy();
            Transition.destroy();
        }
        Dispatch.emit(REDUCED_MOTION_CHANGE, !!reduceMotionQuery.matches);
    };

    onReduceMotionChange();

    reduceMotionQuery.onchange = onReduceMotionChange;

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

    document.body.classList.remove('js:!bg-white');

};

require('doc-ready')(init);
