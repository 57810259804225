export const COMPONENT_INIT = 'COMPONENT_INIT';
export const DOM_CHANGED = 'DOM_CHANGED';
export const MENU_OPENING = 'MENU_OPENING';
export const MENU_OPENED = 'MENU_OPENED';
export const MENU_CLOSING = 'MENU_CLOSING';
export const MENU_CLOSED = 'MENU_CLOSED';
export const HEADER_HIDDEN = 'HEADER_HIDDEN';
export const HEADER_VISIBLE = 'HEADER_VISIBLE';
export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';
//export const ACCORDION_EXPAND = 'ACCORDION_EXPAND';
//export const ACCORDION_COLLAPSE = 'ACCORDION_COLLAPSE';
export const REDUCED_MOTION_CHANGE = 'REDUCED_MOTION_CHANGE';
export const UNLOAD = 'UNLOAD';
