import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Viewport from '../core/Viewport';

const INSTANCES = new WeakMap();

export default button => {

    const region = document.getElementById(button.getAttribute('aria-controls'));
    const closeBtn = region.querySelector('button[data-closebtn]');

    if (!button || !region) {
        console.warn('Popup missing button or region');
        return null;
    }

    let isOpen = false;
    let tl;
    let observer;

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        region.hidden = false;
        button.setAttribute('aria-expanded', 'true');
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                tl = null;
            }
        })
            .fromTo(region, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(region.firstElementChild, { y: -20 }, { y: 0, duration: 0.3, ease: 'Back.easeOut' }, 0);
        closeBtn.focus();
        const siblings = button.parentNode.querySelectorAll('[data-component="Popup"]');
        siblings.forEach(sibling => {
            if (sibling === button) {
                return;
            }
            const popup = INSTANCES.get(sibling);
            if (!popup) {
                return;
            }
            popup.close();
        });
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        button.setAttribute('aria-expanded', 'false');
        if ((!document.activeElement || region.contains(document.activeElement)) && Viewport.visible(button)) {
            button.focus();
        }
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline({
            onComplete() {
                region.hidden = true;
                tl = null;
            }
        })
            .to(region, { opacity: 0, duration: 0.3 }, 0)
            .to(region.firstElementChild, { y: -20, duration: 0.3, ease: 'Power3.easeIn' }, 0);
        if (!tween) {
            tl.progress(1);
        }
    };

    const onClick = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onBodyClick = e => {
        if (!isOpen || e.target === button || button.contains(e.target) || e.target === region || region.contains(e.target)) {
            return;
        }
        close();
    };

    const onBodyKeyUp = e => {
        if (!isOpen || e.key !== 'Escape') {
            return;
        }
        close();
    };

    const init = () => {
        closeBtn.addEventListener('click', close);
        button.addEventListener('click', onClick);
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isOpen && !isIntersecting) {
                close(false);
            }
        });
        observer.observe(region);
        document.body.addEventListener('click', onBodyClick);
        document.body.addEventListener('keyup', onBodyKeyUp);
    };

    const destroy = () => {
        button.removeEventListener('click', onClick);
        closeBtn.removeEventListener('click', close);
        document.body.removeEventListener('click', onBodyClick);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        INSTANCES.delete(button);
        close(false);
    };

    if (ENV !== 'production') {
        Dispatch.emit(COMPONENT_INIT);
    }

    const popup = {
        init,
        destroy,
        close
    };

    INSTANCES.set(button, popup);

    return popup;

};
