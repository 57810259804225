import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const model = el.querySelector('[data-model]');
    const dotsContainer = model.querySelector('[data-dots]');
    const nextBtn = el.querySelector('[data-nav="next"]');
    const prevBtn = el.querySelector('[data-nav="prev"]');

    let dotsTl;
    let observer;
    let pulseTimer;

    const setActivePanel = panelId => {
        const activeButton = model.querySelector(`button[aria-controls="${panelId}"]`);
        if (activeButton.classList.contains('active')) {
            return;
        }
        if (pulseTimer) {
            clearTimeout(pulseTimer);
        }
        const tl = gsap.timeline();
        const activePanel = document.getElementById(activeButton.getAttribute('aria-controls'));
        const oldActiveButton = model.querySelector('button[aria-expanded].active');
        let oldActivePanel;
        if (oldActiveButton) {
            oldActiveButton.classList.remove('active');
            oldActiveButton.setAttribute('aria-expanded', 'false');
            oldActivePanel = document.getElementById(oldActiveButton.getAttribute('aria-controls'));
        }
        if (oldActivePanel) {
            oldActivePanel.style.pointerEvents = 'none';
            oldActivePanel.setAttribute('tabindex', '-1');
            const reversedChildren = Array.from(oldActivePanel.children).reverse();
            gsap.killTweensOf([oldActivePanel, reversedChildren]);
            tl.to(reversedChildren, { opacity: 0, duration: 0.3, stagger: 0.1 }, 'out');
            tl.to(reversedChildren, { y: 5, duration: 0.3, ease: 'Quad.easeIn', stagger: 0.1 }, 'out');
            tl.set(oldActivePanel, { visibility: 'hidden' });
        }
        activeButton.classList.add('active');
        activeButton.setAttribute('aria-expanded', 'true');
        activePanel.style.pointerEvents = '';
        activePanel.style.visibility = '';
        activePanel.removeAttribute('tabindex');
        gsap.killTweensOf([activePanel, activePanel.children]);
        tl.fromTo(activePanel.children, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn', stagger: 0.1 }, 'in-=0.15');
        tl.fromTo(activePanel.children, { y: 15 }, { y: 0, duration: 0.5, ease: 'Quad.easeOut', stagger: 0.1 }, 'in-=0.15');
        pulseTimer = setTimeout(() => {
            clearTimeout(pulseTimer);
            pulseTimer = null;
            model.querySelectorAll('[data-pulse]').forEach(pulse => pulse.classList.remove('pulse'));
            activeButton.querySelector('[data-pulse]').classList.add('pulse');
        }, 300);
    };

    const navigate = direction => {
        const allPanels = Array.from(el.querySelectorAll('[data-panel]'));
        const activeButton = model.querySelector('button[aria-expanded="true"]');
        let activePanel;
        if (activeButton) {
            activePanel = document.getElementById(activeButton.getAttribute('aria-controls'));
        }
        activePanel = activePanel || allPanels[0];
        let nextActivePanel;
        if (direction === 'next') {
            nextActivePanel = activePanel.nextElementSibling || allPanels[0];
        } else {
            nextActivePanel = activePanel.previousElementSibling || allPanels[allPanels.length - 1];
        }
        setActivePanel(nextActivePanel.id);
    };

    const onPrevBtnClick = () => {
        navigate('prev');
    };

    const onNextBtnClick = () => {
        navigate('next');
    };

    const onModelButtonClick = e => {
        const { currentTarget: button } = e;
        setActivePanel(button.getAttribute('aria-controls'));
    };

    const init = () => {
        model.querySelectorAll('button[aria-expanded]').forEach(button => {
            button.addEventListener('click', onModelButtonClick);
        });
        nextBtn.addEventListener('click', onNextBtnClick);
        prevBtn.addEventListener('click', onPrevBtnClick);
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
                if (!dotsTl) {
                    const buttonDots = model.querySelectorAll('button [data-dot]');
                    dotsTl = gsap.timeline({
                        repeat: -1,
                        paused: true
                    })
                        .to(dotsContainer, { rotation: 90, duration: 3, ease: 'Cubic.easeInOut' })
                        .to(buttonDots, { scale: 1.25, duration: 0.3, repeat: 1, yoyo: true }, '-=0.5')
                        .to(dotsContainer, { rotation: 180, duration: 3, ease: 'Cubic.easeInOut' })
                        .to(buttonDots, { scale: 1.25, duration: 0.3, repeat: 1, yoyo: true }, '-=0.5')
                        .to(dotsContainer, { rotation: 270, duration: 3, ease: 'Cubic.easeInOut' })
                        .to(buttonDots, { scale: 1.25, duration: 0.3, repeat: 1, yoyo: true }, '-=0.5')
                        .to(dotsContainer, { rotation: 360, duration: 3, ease: 'Cubic.easeInOut' })
                        .to(buttonDots, { scale: 1.25, duration: 0.3, repeat: 1, yoyo: true }, '-=0.5');
                }
                dotsTl.play();
            } else if (dotsTl) {
                dotsTl.pause();
            }
        });
        observer.observe(el);
    };

    const destroy = () => {
        if (pulseTimer) {
            clearTimeout(pulseTimer);
        }
        observer.disconnect();
        observer = null;
        if (dotsTl) {
            dotsTl.kill();
            dotsTl = null;
        }
        nextBtn.removeEventListener('click', onNextBtnClick);
        prevBtn.removeEventListener('click', onPrevBtnClick);
        model.querySelectorAll('button[aria-expanded]').forEach(button => {
            button.removeEventListener('click', onModelButtonClick);
        });
    };

    Dispatch.emit(COMPONENT_INIT);

    return {
        init,
        destroy
    };

};
